import React from "react";
import "./LoadingSpinner.css";

function LoadingSpinner() {
  return (
    <div className="loading-spinner">
      <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g
          id="02-Basic-Inputs"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Bits-&amp;-Loaders-1312px-/-16-Copy-2"
            transform="translate(-392.000000, -448.000000)"
          >
            <g id="AmpBasicSmall" transform="translate(392.000000, 448.000000)">
              <path
                d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M10,4 C6.6862915,4 4,6.6862915 4,10 C4,13.3137085 6.6862915,16 10,16 C13.3137085,16 16,13.3137085 16,10 C16,6.6862915 13.3137085,4 10,4 Z"
                id="spinner-circle"
                fill="#E1E1E1"
              />
              <path
                d="M10,0 L10,4 C6.6862915,4 4,6.6862915 4,10 C4,12.0133587 4.99166921,13.7951006 6.51321137,14.8834295 L4.18685298,18.1377379 C1.65198576,16.3237368 0,13.3547894 0,10 C0,4.4771525 4.4771525,0 10,0 Z"
                id="spinner-bar"
                fill="#FF9900"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default LoadingSpinner;
